




































































































































































import {Component, Prop, Vue} from "vue-property-decorator";
import ITRAddressDTO from "@/dto/taxation/individual/ITRAddressDTO";
import RegexUtils from "@/utils/RegexUtils";
import {STATE_LIST} from "@/constants/ProfileConstants";

@Component
export default class ITRAddressCard extends Vue {

  private states = STATE_LIST

  @Prop()
  private addresses!: ITRAddressDTO[];

  public mainAddress: ITRAddressDTO = this.getMainAddress;

  private assembledActualAddress: string | null = this.isAddressPresent ? this.assembleAddress(this.mainAddress) : null;

  private inputAddressPresent = this.isAddressPresent;

  private isStatementTrue: boolean | null = null;

  private tabIndex = 0;

  mounted() {
    this.updateTabIndex();
  }

  assembleAddress(address: ITRAddressDTO) {
    return address.address1 + " " + address.city + " " + address.state + " " + address.zipCode;
  }

  get isAddressPresent(): boolean {
    return !!(this.addresses.length != 0
        && this.addresses[0]
        && this.addresses[0].address1
        && this.addresses[0].city
        && this.addresses[0].state
        && this.addresses[0].zipCode
    );
  }

  get isEmptyFields(): boolean {
    return !this.mainAddress.address1
        || !this.mainAddress.city
        || !this.mainAddress.state
        || !this.mainAddress.zipCode
  }

  handleAddress(event: KeyboardEvent) {
    if (!RegexUtils.testAddressCharacters(event.key)) {
      event.preventDefault();
    }
  }

  handleZip(event: KeyboardEvent) {
    if (!RegexUtils.testNumeric(event.key)) {
      event.preventDefault();
    }
  }

  get getMainAddress(): ITRAddressDTO {
    if (this.isAddressPresent) {
      this.inputAddressPresent = true;
      return this.addresses[0];
    } else {
      let mainAddress = new ITRAddressDTO();
      mainAddress.addressNum = 0;
      this.addresses.push(mainAddress);
      this.inputAddressPresent = false;

      return mainAddress;
    }
  }

  zipCodeHandler() {
    if (this.mainAddress.zipCode.length > 5) {
      this.mainAddress.zipCode = this.mainAddress.zipCode.substr(0, 5);
    }
  }

  updateTabIndex() {
    this.tabIndex = this.mainAddress ? !this.mainAddress.residental ? 1 : 0 : 0;
  }

  changeTab(tabIndex: number) {
    this.tabIndex = tabIndex;
    this.mainAddress.residental = tabIndex === 0;
  }

}
